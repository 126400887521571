.ProductsPanel{
    display: flex;
    width:30%;
    Height:80%;
    margin-left :60%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid grey;
}
.ProductsHeader{
    font-size: xx-large;
}